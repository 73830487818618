<template>
    <b-row>
      <!-- <b-col lg="12" sm="12"> -->
        <b-overlay :show="loading">
           <b-row class="text-right mt-2">
            <b-col class="text-right">
               <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
               </b-button>
                <a style="color: white" title="Excel" class="btn btn-sm btn-warning float-right" :href="`${teaGardenServiceBaseUrl}garden/return-management/return-two/excel-export/${id}/${$i18n.locale}`" target="_blank">{{  $t('globalTrans.export_excel') }}</a>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" xs="12" md="12">
                <b-row>
                    <b-col lg="12" xs="12" md="12">
                      <h5 class="text-center">{{ $i18n.locale === 'en' ? 'Form-2 (Monthly)' : 'ফরম-২ (মাসিক)' }}</h5>
                    </b-col>
                    <b-col lg="12" xs="12" md="12" class="mt-1">
                      <list-report-head :base-url="baseUrl" uri="/configuration/report-heading/detail" :org-id="5">
                        <template v-slot:projectNameSlot>
                        </template>
                        {{ $i18n.locale === 'en' ? 'Monthly Return' : 'মাসিক রিটার্ণ' }}
                      </list-report-head>
                    </b-col>
                </b-row>
                <b-row v-if="detailsData">
                    <b-col style="font-size: 14px" sm="4">
                      <div>
                        <span v-if="$i18n.locale === 'en'">1. Name of the tea Garden/factory<br>and address:</span>
                        <span v-else>১। বাগান/কারখানার নাম <br>ও ঠিকানাঃ </span>
                        <span><b> {{ getGardenFactName(detailsData.return_type, detailsData.garden_fact_id) + ', ' }}<br>{{ (currentLocale == 'en' ? detailsData.garden.garden_address_en : detailsData.garden.garden_address_bn) + ', ' + getAddress(detailsData.return_type) }}</b></span>
                      </div>
                      <br>
                      <div>
                        <span>{{ $i18n.locale === 'en' ? 'e-mail: ' : 'ই-মেইলঃ '}}</span>
                        <span><b>{{ detailsData.return_type == 1 ? detailsData.garden.email : '' }}</b></span>
                      </div>
                    </b-col>
                    <b-col sm="4">
                        <div class="text-center"><b>{{ $t('globalTrans.month')}} : </b> <span class="">{{getMonthName(detailsData.month)}},</span> {{$n(detailsData.year,{useGrouping:false})}}</div>
                    </b-col>
                    <b-col style="font-size: 14px" sm="4">
                      <div>
                        <b><span v-if="$i18n.locale === 'en'"> Quantity In KG</span>
                        <span v-else> পরিমানঃ কেজিতে </span></b><br>
                        <b><span v-if="$i18n.locale === 'en'"> Value In Taka</span>
                        <span v-else> মূল্যঃ টাকায় </span></b>
                      </div>
                      <br>
                      <div style="margin-top: 20px">{{ $t('globalTrans.mobile_no') + ': ' }} <span v-if="detailsData.return_type == 1"><b>{{ detailsData.garden.mobile | mobileNumber }}</b></span></div>
                    </b-col>
                </b-row>
            </b-col>
          </b-row>
          <b-row v-if="detailsData">
            <!-- Tab - One -->
            <b-col lg="12" xs="12" md="12">
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.production') }}</b-th>
                          <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.disposal') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th rowspan="2">{{ $t('teaGarden.type_of_manufacture') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.upto_pre_month') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.during_the_month') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.upto_beg_month') }}<br> {{currentLocale == 'en' ? '(Col. 2+3)' : '(কলাম ২+৩)' }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.open_stock_beg') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.total_available_tea') }} <br> {{currentLocale == 'en' ? '(Col. 3+5)' : '(কলাম ৩+৫)' }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.type_of_disposal') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.quantity_pre_disposed') }}</b-th>
                          <b-th rowspan="1" colspan="2">{{ $t('teaGarden.disposal_during_the_month') }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.qty_disposed_upto_beg_year') }}<br> {{currentLocale == 'en' ? '(Col. 8+9)' : '(কলাম ৮+৯)' }}</b-th>
                          <b-th rowspan="2">{{ $t('teaGarden.blaance_stock') }}<br> {{currentLocale == 'en' ? '(Col. 6-9)' : '(কলাম ৬-৯)' }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.quantity') }}</b-th>
                          <b-th>{{ $t('teaGarden.approximate_value') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(1) }}</b-th>
                          <b-th>{{ $n(2) }}</b-th>
                          <b-th>{{ $n(3) }}</b-th>
                          <b-th>{{ $n(4) }}</b-th>
                          <b-th>{{ $n(5) }}</b-th>
                          <b-th>{{ $n(6) }}</b-th>
                          <b-th>{{ $n(7) }}</b-th>
                          <b-th>{{ $n(8) }}</b-th>
                          <b-th>{{ $n(9) }}</b-th>
                          <b-th>{{ $n(10) }}</b-th>
                          <b-th>{{ $n(11) }}</b-th>
                          <b-th>{{ $n(12) }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle" v-for="(data, index) in dataProduction" :key="index">
                            <b-td>{{ returnTypeList(index) }}</b-td>
                            <b-td>{{ $n(data.pre_month_beg) }}</b-td>
                            <b-td>{{ $n(data.current_month) }}</b-td>
                            <b-td>{{ $n(data.upto_month) }}</b-td>
                            <b-td v-if="index === 0" rowspan="6" style="vertical-align: middle;">{{ $n(data.opening_stock) }}</b-td>
                            <b-td>{{ $n(parseFloat(data.current_month)+parseFloat(data.opening_stock)) }}</b-td>
                            <b-td>{{ dataSale[index] != undefined ? getTypeOfDispo(dataSale[index].id) : ''}}</b-td>
                            <b-td>{{ dataSale[index] != undefined ? $n(dataSale[index].qty_upto_pre) : ''}}</b-td>
                            <b-td>{{ dataSale[index] != undefined ? $n(dataSale[index].quantity) : ''}}</b-td>
                            <b-td>{{ dataSale[index] != undefined ? $n(dataSale[index].value) : ''}}</b-td>
                            <b-td>{{ dataSale[index] != undefined ? $n(parseFloat(dataSale[index].qty_upto_pre + parseFloat(dataSale[index].quantity))) : ''}}</b-td>
                            <b-td>{{ dataSale[index] != undefined && dataSale[index].quantity > 0 ? $n(totalAvailableTea() - parseFloat(dataSale[index].quantity) ) : '' }}</b-td>
                        </b-tr>
                         <b-tr class="text-center align-middle">
                          <b-th>{{ $t('globalTrans.total') }}</b-th>
                          <b-th>{{ $n(totalPreMonthPro()) }}</b-th>
                          <b-th>{{ $n(totalcurrentMonth()) }}</b-th>
                          <b-th>{{ $n(totalBegStockMonth()) }}</b-th>
                          <b-th>{{ $n(totalOpeingStock()) }}</b-th>
                          <b-th>{{ $n(totalAvailableTea()) }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}</b-th>
                          <b-th>{{ $n(qtyDisPreMonth()) }}</b-th>
                          <b-th>{{ $n(totalQty()) }}</b-th>
                          <b-th>{{ $n(totalAllValue(), { minimumFractionDigits: 2 }) }}</b-th>
                          <b-th>{{ $n(totalYrSaleQty()) }}</b-th>
                          <b-th>{{ $n(totalStock()) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <!-- Tab - One - End -->
          </b-row>
        </b-overlay>
      <!-- </b-col> -->
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoReportApi } from '../../api/routes'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import AddressHelper from '@/utils/area-type-address'

export default {
    name: 'Details',
    props: ['id'],
    components: { ListReportHead },
    data () {
      return {
        AddressHelper: AddressHelper,
        baseUrl: teaGardenServiceBaseUrl,
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        loading: false,
        detailsData: null,
        dataProduction: null,
        exgarden_pre_qty: null,
        dataSale: null,
        totalOpeingStockPdf: 0,
        totalBegStockMonthPdf: 0,
        totalcurrentMonthPdf: 0,
        totalPreMonthProPdf: 0,
        qtyDisPreMonthPdf: 0,
        totalQtyPdf: 0,
        totalAllValuePdf: 0,
        totalYrSaleQtyPdf: 0,
        totalMonthlyStockPdf: 0,
        totalAvailableTeaPdf: 0
      }
    },
    computed: {
      masterTeaGardenGenInfoList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
      },
      masterBoughtLeafFactoryList () {
          return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    created () {
      if (this.id) {
        this.loading = true
        this.getDetailsData()
      }
    },
    methods: {
      getPreData (data) {
        if (data) {
          return data.qty_upto_pre
        } else {
          return ''
        }
      },

      getMonthName (id) {
        const data = this.$store.state.commonObj.monthList.find(item => item.value === id)
        if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        } else {
          return ''
        }
      },
    returnTypeList (id) {
       const TypeList = [
          { text: this.$i18n.locale === 'bn' ? 'সি টি সি' : 'C T C', value: 0 },
          { text: this.$i18n.locale === 'bn' ? 'অর্থডক্স চা' : 'Orthodox Tea', value: 1 },
          { text: this.$i18n.locale === 'bn' ? 'সবুজ চা' : 'Green Tea', value: 2 },
          { text: this.$i18n.locale === 'bn' ? 'সাদা চা' : 'White Tea', value: 3 },
          { text: this.$i18n.locale === 'bn' ? 'হলুদ চা' : 'Yellow Tea', value: 4 },
          { text: this.$i18n.locale === 'bn' ? 'অন্যান্য চা' : 'Others Tea', value: 5 }
      ]
      return TypeList.find(item => item.value === id).text
    },
    getTypeOfDispo (id) {
       const TypeList = [
          { text: this.$i18n.locale === 'bn' ? 'এক্স গার্ডেন সেলস' : 'Ex-Garden Sale', value: 0 },
          { text: this.$i18n.locale === 'bn' ? 'নিলামের জন্য প্রেরণ' : 'Dispatch for Auction', value: 1 },
          { text: this.$i18n.locale === 'bn' ? 'সরাসরি চুক্তি রপ্তানি' : 'Direct Contract Export', value: 2 },
          { text: this.$i18n.locale === 'bn' ? 'লন্ডন নিলামে চালান' : 'Consignment to London Auction', value: 3 },
          { text: this.$i18n.locale === 'bn' ? 'বিনামূল্যে বিতরণ' : 'Free Distribution', value: 4 }
      ]
      return TypeList.find(item => item.value === id).text
    },
      getAddress (type) {
        let address = ''
        if (parseInt(type) === 1) {
          address = this.AddressHelper.getUpazilaName(this.detailsData.garden.upazila_id)
          address = address + (address ? ', ' : '') + this.AddressHelper.getDistrictName(this.detailsData.garden.district_id)
        } else {
          address = this.AddressHelper.getUpazilaName(this.detailsData.factory.upazila_id)
          address = address + (address ? ', ' : '') + this.AddressHelper.getDistrictName(this.detailsData.factory.district_id)
        }
        return address
      },
      getGardenFactName (type, Id) {
        const List = parseInt(type) === 1 ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
        const Obj = List.find(el => el.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
            return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      BangNum (n) {
         if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
         }
         return n
      },
      async getDetailsData () {
        const resultData = await RestApi.getData(teaGardenServiceBaseUrl, returnTwoReportApi, { app_id: this.id })
        if (resultData.success) {
          this.detailsData = resultData.data
          this.dataProduction = resultData.dataProduction
          this.dataSale = resultData.dataSale
          this.exgarden_pre_qty = resultData.exgarden_pre_qty
          this.loading = false
        } else {
          this.detailsData = null
          this.dataProduction = null
          this.dataSale = null
          this.exgarden_pre_qty = null
          this.loading = false
        }
      },
      async pdfExport () {
        this.loader = true
        const params = Object.assign({
          request_type: 'pdf',
          local: this.$i18n.locale,
          org_id: 5,
          id: this.id,
          app_id: this.id
         })
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 26)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, returnTwoReportApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loader = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      totalAvailableTea () {
          const sum = this.dataProduction.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.current_month + currentValue.opening_stock)
          }, 0)
          this.totalAvailableTeaPdf = sum
          return sum
      },
      totalOpeingStock () {
          const sum = this.dataProduction.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.opening_stock)
          }, 0)
          this.totalOpeingStockPdf = sum
          return sum
      },
      totalBegStockMonth () {
          const sum = this.dataProduction.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.upto_month)
          }, 0)
          this.totalBegStockMonthPdf = sum
          return sum
      },
      totalcurrentMonth () {
          const sum = this.dataProduction.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.current_month)
          }, 0)
          this.totalcurrentMonthPdf = sum
          return sum
      },
      totalPreMonthPro () {
          const sum = this.dataProduction.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.pre_month_beg)
          }, 0)
          this.totalPreMonthProPdf = sum
          return sum
      },
      qtyDisPreMonth () {
          const sum = this.dataSale.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.qty_upto_pre)
          }, 0)
          this.qtyDisPreMonthPdf = sum
          return sum
      },
      totalQty () {
          const sum = this.dataSale.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.quantity)
          }, 0)
          this.totalQtyPdf = sum
          return sum
      },
      totalAllValue () {
          let totalSaleQty = 0
          let totalSalePrice = 0
          this.dataSale.forEach(item => {
            totalSalePrice += parseFloat(item.value) * parseFloat(item.quantity)
            totalSaleQty += parseFloat(item.quantity)
          })

          const avgValue = totalSalePrice / totalSaleQty
          this.totalAllValuePdf = avgValue
          return avgValue.toFixed(2)
          // const sum = this.dataSale.reduce((accumulator, currentValue) => {
          //   return accumulator + parseFloat(currentValue.value)
          // }, 0)
          // this.totalAllValuePdf = sum
          // return sum
      },
      totalYrSaleQty () {
          const sum = this.dataSale.reduce((accumulator, currentValue) => {
            return accumulator + (parseFloat(currentValue.qty_upto_pre) + parseFloat(currentValue.quantity))
          }, 0)
          this.totalYrSaleQtyPdf = sum
          return sum
      },
      totalMonthlyStock () {
          const sum = this.dataSale.reduce((accumulator, currentValue) => {
            return accumulator + (parseFloat(this.totalAvailableTea()) - parseFloat(currentValue.quantity))
          }, 0)
          this.totalMonthlyStockPdf = sum
          return sum
      },
      totalStock () {
        let stockTotal = 0
        this.dataProduction.forEach((production, key) => {
            if (this.dataSale[key] && this.dataSale[key].quantity > 0) {
                stockTotal += parseFloat(this.dataSale[key].quantity)
            } else {
                stockTotal += 0
            }
        })
        const availableQty = this.totalAvailableTea() - stockTotal
        return availableQty.toFixed(2)
      }
    }
}
</script>
<style scoped>
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table-bordered tbody th, .table-bordered tbody td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}
</style>
