<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <template v-if="!isView">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col sm="12">
              <ValidationProvider name="Review comments" vid="review_comments" rules="required|max:255">
                <b-form-group
                  label-for="review_comments"
                  slot-scope="{ errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.comments')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                      id="review_comments"
                      v-model="formData.comments"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                  </b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-review')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template v-else>
          <div>
            <p>
              <strong>{{$t('globalTrans.comments')}} : </strong>{{items.review_comments}}
            </p>
          </div>
      </template>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoReviewSaveApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['items', 'isView'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.return'),
      errors: [],
      formData: {
        comments: ''
      }
    }
  },
  created () {
   if (this.items) {
      this.formData.id = this.items.id
      this.formData.updated_by = this.$store.state.Auth.authUser.user_id
    }
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    async saveUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      let result = null
      result = await RestApi.postData(teaGardenServiceBaseUrl, `${returnTwoReviewSaveApi}/${parseInt(this.items.id)}`, this.formData)
      this.loading = false
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: result.message,
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-review')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
