<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_two') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="year" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="year"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.year')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.year"
                      :options="yearList"
                      id="year"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Month" vid="month" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="month"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.month')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.month"
                      :options="monthList"
                      id="month"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Type Garden Factory" vid="type_garden_factory_id" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="type_garden_factory_id"
                  >
                  <template v-slot:label>
                      {{$t('teaGarden.type_garden_factory')}}
                  </template>
                  <b-form-select
                      :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                      plain
                      v-model="search.return_type"
                      :options="returnTypeList"
                      id="type_garden_factory_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Tea Garden Factory Name" vid="garden_factory_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="garden_factory_id"
                  >
                  <template v-slot:label>
                      {{$t('teaGarden.garden_factory_name')}}
                  </template>
                  <b-form-select
                      :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                      plain
                      v-model="search.garden_fact_id"
                      :options="gardenList"
                      id="garden_factory_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col sm="3">
                  <b-button type="button" @click="searchData" class="mt-20" size="sm" variant="primary">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
              </b-col>
          </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_two') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link to="return-two-entry" class="btn-add"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(year)="data">
                    {{ $n(data.item.year, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(month)="data">
                    {{ getMonthName(data.item.month) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.status === 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-success" v-if="data.item.admin_final_status === 4">{{$t('globalTrans.approved')}}</span>
                    <span class="badge badge-danger" v-if="data.item.admin_final_status === 5 && $store.state.Auth.activeRoleId === 1">{{$t('globalTrans.returned_to')}}</span>
                    <span class="badge badge-danger" v-if="data.item.admin_final_status === 5 && $store.state.Auth.activeRoleId !== 1">{{$t('globalTrans.returned')}}</span>
                    <span class="badge badge-info" v-if="data.item.status === 2 && data.item.admin_final_status !== 5 && data.item.admin_final_status !== 4">{{$t('globalTrans.submitted')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- <router-link class="moc-action-btn moc-view-btn" :to="'return-form-three-view/'+data.item.id" :title="$t('globalTrans.view')">
                      <i class="ri-eye-line"></i>
                    </router-link> -->
                    <b-button  v-b-modal.details variant="action-btn status mr-1" size="sm" @click="details(data.item.id)" class="action-btn status" :title="$t('globalTrans.details')"><i class="ri-eye-line"></i></b-button>
                    <router-link class="action-btn edit" v-if="data.item.status == 1 || data.item.admin_final_status === 5" :to="`return-two-entry?id=${data.item.id}`" :title="$t('globalTrans.edit')">
                      <i class="ri-pencil-fill"></i>
                    </router-link>
                    <span v-if="data.item.status === 2">
                     <router-link class="action-btn edit" v-if="data.item.admin_final_status < 3 && $store.state.Auth.activeRoleId === 1"  :to="`return-two-entry?id=${data.item.id}`" :title="$t('globalTrans.edit')">
                      <i class="ri-pencil-fill"></i>
                    </router-link>

                    <!-- approved button -->
                       <b-button v-if="data.item.admin_final_status < 4 && $store.state.Auth.activeRoleId === 1" variant="iq-bg-success" size="sm" class="action-btn status" @click="confirmApprove(data.item.id)" :title="$t('globalTrans.approve')"><i class="ri-check-line"></i></b-button>

                       <!-- review button -->
                       <b-button :title="$t('globalTrans.return')" v-if="data.item.admin_final_status < 4 && $store.state.Auth.activeRoleId === 1 && !data.item.review_comments" v-b-modal.modal-review class="moc-action-btn moc-return-btn" size="sm" @click="review(data.item)"><i class="ri-reply-all-fill"></i></b-button>
                       <!-- review view button -->
                       <b-button :title="$t('globalTrans.comments')" v-if="data.item.admin_final_status === 5" v-b-modal.modal-review class="moc-action-btn moc-resubmit-btn" size="sm" @click="review(data.item, 1)"> <i class="ri-feedback-line"></i></b-button>
                    </span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="details" size="xl" :title="$t('teaGarden.return_two') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :id="viewitemId" :key="viewitemId"/>
    </b-modal>
    <b-modal id="modal-review" size="md" :title="$t('globalTrans.review')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Review :items="reviewData" :isView="isView"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoListApi, exGardenAppStatus, returnTwoApproveApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import teaGardenService from '@/mixins/tea-garden-service'
import Details from './Details.vue'
import { helpers } from '@/utils/helper-functions'
import Review from './Review.vue'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    Details, Review
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      isGardenAdmin: false,
      viewitemId: 0,
      reviewData: '',
      isView: false,
      search: {
        return_type: 0,
        garden_fact_id: 0,
        year: 0,
        month: 0,
        limit: 20
      },
      gardenList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'globalTrans.year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.month', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.garden_factory_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.garden_factory_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 10, thStyle: { width: '12%' } }
      ]
    }
  },
  created () {
    this.search.year = new Date().getFullYear()
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.search.return_type = 1
      this.search.garden_fact_id = this.isGardenAdminCheckGardenId()
    } else {
      this.search.return_type = 0
    }
     if (this.isFactoryAdminCheckFactoryId()) {
          const gardenData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === this.isFactoryAdminCheckFactoryId())
          this.search.return_type = gardenData.factory_type_id
          this.search.garden_fact_id = this.isFactoryAdminCheckFactoryId()
     }
    this.loadData()
  },
  watch: {
    'search.return_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.gardenFactList()
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.search.return_type) {
          this.gardenFactList()
        }
      }
    }
  },
  computed: {
    masterTeaGardenGenInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
    },
    masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
        })
    },
    returnTypeList () {
      return [
          { text: this.$i18n.locale === 'bn' ? 'বাগান' : 'Garden', value: 1 },
          { text: this.$i18n.locale === 'bn' ? 'কারখানা' : 'Factory', value: 2 }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'month' },
          { key: 'garden_factory_id' },
          { key: 'garden_factory_name_bn' },
          { key: 'type_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'month' },
          { key: 'garden_factory_id' },
          { key: 'garden_factory_name' },
          { key: 'type_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGarden.online_application') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.online_application') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
    details (id) {
        this.viewitemId = id
    },
    review (item, isView) {
      this.reviewData = item
      this.isView = isView
    },
    gardenFactList () {
      if (this.search.return_type === 1) {
        this.gardenList = this.masterTeaGardenGenInfoList
      } else {
        this.gardenList = this.masterBoughtLeafFactoryList
      }
    },

    getMonthName (id) {
      const data = this.$store.state.commonObj.monthList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      } else {
        return ''
      }
    },
    /** Garden List filter end */
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(3, teaGardenServiceBaseUrl, exGardenAppStatus, item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, returnTwoListApi, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const arrayList = item.return_type === 1 ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
          const tmpType = this.$store.state.TeaGardenService.commonObj.returnTypeList.find(el => el.value === item.return_type)
          const garden = arrayList.find(data => data.value === parseInt(item.garden_fact_id))
          const gardData = {
            garden_factory_id: item.return_type === 1 ? garden.garden_id_no : garden.registration_no,
            garden_factory_name: garden !== undefined ? garden.text_en : '',
            garden_factory_name_bn: garden !== undefined ? garden.text_bn : '',
            type_en: tmpType !== undefined ? tmpType.text_en : '',
            type_bn: tmpType !== undefined ? tmpType.text_bn : ''
          }
          return Object.assign({}, item, { serial: index }, gardData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    confirmApprove (appId) {
      this.$swal({
        title: this.$t('globalTrans.surePrompt'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
            this.approve(appId)
        }
      })
    },
    async approve (appId) {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.postData(teaGardenServiceBaseUrl, `${returnTwoApproveApi}/${parseInt(appId)}`)
      this.loading = false
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.$t('globalTrans.update_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('globalTrans.sorry_error'),
          color: '#DC143C'
        })
      }
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
